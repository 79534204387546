import React from 'react'
import { Wrapper } from 'styles'
import { ProductOverview } from 'components/product'
import { transformShopifyStorefrontImagesToGatsbyImage } from 'utilities'
import styled, { createGlobalStyle } from 'styled-components'
import { ProductCopy } from 'types/misc'
import { useFetchShopifyProductByHandle } from 'hooks'
import { GatsbyShopifyProduct, GatsbyShopifyStorefrontProduct } from 'lib/shopify/storefront-api-client/types/custom.types'

type Props = {
  initialShopifyProduct?: GatsbyShopifyProduct
  productPage: ProductCopy
}

const SanityShopifyProduct = ({ productPage, initialShopifyProduct }: Props) => {
  const shopifyProduct = useFetchShopifyProductByHandle(productPage.handle)
  let productTransformed: GatsbyShopifyStorefrontProduct | null = null;

  if (!shopifyProduct) return null;

  if (initialShopifyProduct) {
    productTransformed = {
      ...initialShopifyProduct,
      variants: shopifyProduct.variants,
      availableForSale: shopifyProduct.availableForSale
    }
  } else {
    productTransformed = transformShopifyStorefrontImagesToGatsbyImage(shopifyProduct);
  }

  if (!productTransformed) return null;

  return (
    <>
      <GlobalStyle theme={productPage.theme} />
      <Container className={`theme-${productPage.theme}`}>
        <ProductWrapper>
          <ProductOverview
            product={productTransformed}
            productPage={productPage}
            productMedia={productTransformed.media}
          />
        </ProductWrapper>
      </Container>
    </>
  )
}

const ProductWrapper = styled(Wrapper)`
  padding-top: 4rem;
`

const GlobalStyle = createGlobalStyle`
  main {
    background-color: ${({ theme }) => {
      if (theme === 'green') return 'var(--darkGreen) !important';
      if (theme === 'gray') return 'var(--lightestGray) !important';
      if (theme === 'white') return 'white !important';
      if (theme === 'primary') return 'var(--primaryColor) !important';
      if (theme === 'secondary') return 'var(--accentColor) !important';
    }
  }
`;

const Container = styled.div`
  &.theme-green, 
  &.theme-primary, 
  &.theme-secondary,
  &.theme-green {
    color: var(--white);
    
    .buy-button, 
    .amazon-button {
      &:disabled {
        background-color: var(--lightGray);
        border-color: var(--lightGray);
        &:hover {
          background-color: var(--lightGray);
          border-color: var(--lightGray);
        }
      }
    }
    
    .variant-selector-button.product-single-variant.active {
      border: 2px solid black;
      background-color: var(--white);
    }   

    .out-of-stock-alert {
      background-color: var(--green);
    }
  }

  &.theme-green {
    .buy-button, 
    .amazon-button {
      background-color: var(--green);
      border: 2px solid var(--green);
      &:hover {
        background-color: transparent;
        border: 2px solid var(--green);
      }
    }

    .variant-selector-button {
      background: transparent;
      border: 2px solid var(--green);
      color: white;
      &.active {
        background-color: var(--green);
        color: white;
        font-weight: bold;
        border: 2px solid var(--green);
      }
    }
  }

  &.theme-primary {
    .buy-button, 
    .amazon-button {
      background-color: var(--primaryBlue);
      &:hover {
        background-color: var(--colorPrimary);
        border: 2px solid var(--primaryBlue);
      }
    }

    .variant-selector-button {
      background: transparent;
      border: 2px solid var(--primaryBlue);
      color: var(--primaryBlue);
      &.active {
        background-color: var(--white);
        color: var(--primaryColor);
        font-weight: bold;
        border: 2px solid var(--primaryBlue);
      }
    }
  }

  &.theme-gray,
  &.theme-white {
    .product-feature {
      color: var(--black);
    }
  }

  &.theme-green {
    background-color: var(--darkGreen);
  }

  &.theme-gray {
    background-color: var(--lightestGray);
  }

  &.theme-white {
    background-color: white;
  }

  &.theme-primary {
    background-color: var(--primaryColor);
  }

  &.theme-secondary {
    background-color: var(--accentColor);
  }
`

export default SanityShopifyProduct
