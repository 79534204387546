import React from 'react'
import { trackNewsletterSignup } from 'analytics'
import { EmailInput } from '../inputs'
import { SectionTheme, SectionSize } from 'types/misc'
import { SanitySection } from './SanitySection'
import styled from 'styled-components'
import { subscribeToKlaviyoEmailList, subscribeToKlaviyoList } from '../../lib/klaviyo'

type Props = {
  title?: string
  description?: string
  buttonText?: string
  id?: string
  theme?: SectionTheme | ''
  size?: SectionSize | ''
  listId?: string,
  trackingId?: string
  confirmationText?: string
  listTitle?: string
  listDescription?: string
  listItems?: string[]
  contentBackgroundColor?: string
  contentTextColor?: string
}

function EmailCaptureSection({
  id = '',
  title,
  description,
  size = '',
  theme = 'gray',
  listId,
  trackingId,
  confirmationText,
  buttonText,
  listTitle,
  listDescription,
  listItems = [],
  contentBackgroundColor,
  contentTextColor,
}: Props) {
  const handleEmailSubmit = async (email: string) => {
    if (!listId) {
      await subscribeToKlaviyoEmailList(email);
      return;  
    }

    await subscribeToKlaviyoList(email, listId);
    
    await trackNewsletterSignup(email, trackingId);
  };

  return (
    <Container className='email-capture-section'>
      <SanitySection
        size={size}
        id={id}
        theme={theme}
      >
        <Content 
          backgroundColor={contentBackgroundColor} 
          textColor={contentTextColor}
          hasList={listItems.length > 0}
        >
          <div>
            <h2>{title || 'Stay connected with Rachio!'}</h2>
            <p>{description || 'Get the latest tips, deals, and news straight to your inbox. By clicking Subscribe you are agreeing to the Rachio Terms of Use.'}</p>
          </div>
          <div className='list-section'>
            {listItems?.length ? (
              <div>
                {listTitle && (<h2 className='no-top-margin'>{listTitle}</h2>)}
                {listDescription && (<p>{listDescription}</p>)}
                <ul>
                  {listItems.map((item, index) => (
                    <li key={`${item}-${index}`}>{item}</li>
                  ))}
                </ul>
              </div>
            ) : null}
            <EmailInput
              id="newsletter-submit"
              onSubmit={handleEmailSubmit}
              confirmationText={confirmationText}
              buttonText={buttonText}
            />
          </div>

        </Content>
      </SanitySection>
    </Container>
  )
}

const Content = styled.div<{ backgroundColor?: string; textColor?: string, hasList?: boolean }>`
  ${({ backgroundColor, textColor }) => backgroundColor && `
    border-radius: var(--cardRadius);
    padding: 2rem;
    background-color: ${backgroundColor};
    text-color: ${textColor};
  `}

  h2 {
    margin-top: 0;
  }

  p {
    max-width: 600px;
    margin: 0 auto 3rem;
  }

  text-align: center;

  ${({ hasList }) => hasList && `
    text-align: left;
    display: flex;
    flex-wrap: wrap;

    > div {
      flex: 2;
      padding: 4rem;     
    }

    .list-section {
      ul {
        padding-left: 2rem;
      }
      li {
        margin-bottom: 1rem;
        list-style: circle;
      }
    }

    @media (max-width: 900px) {
      flex-direction: column;
      align-items: center;
      .list-section {
        padding-top: 0;
      }
      p {
        max-width: 100%;
        margin-bottom: 0;
      }
      > div {
        flex: 1;
        width: 100%; 
      }
    }
  `}
`

const Container = styled.div`
  position: relative;
  background: var(--white);

  form {
    margin: 0 auto;
    max-width: 768px;
  }
`

export default EmailCaptureSection
