import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { GatsbyImage, IGatsbyImageData, GatsbyImageProps } from 'gatsby-plugin-image'
import { below } from 'styles'

type Props = {
  children: ReactNode,
  image: IGatsbyImageData
  className: string
  alt: string
  objectFit?: GatsbyImageProps["objectFit"]
}

export const CardBase = ({ children, image, className, alt, objectFit }: Props) => {
  return (
    <CardContainer className={className}>
      <div className="wrapper">
        <div className="image-container">
          <GatsbyImage
            image={image}
            alt={alt}
            objectFit={objectFit || "cover"}
            loading="eager"
          />
        </div>

        <div className="content-section">
          {children}
        </div>
      </div>
    </CardContainer>
  )
}

export const CardContainer = styled.div`
  padding: 40px 0;
  .wrapper {
    background: var(--white);
    border: 1px solid #dbd8d6;
    border-radius: 20px;
    overflow: hidden;
    width: 450px;
    max-width: 95%;
    margin: 0 auto;
    box-shadow: 5px 5px 16px -10px;
  }
  .image-container {
    div {
      max-height: 250px;
    }
    max-height: 250px;
    text-align: center;
    ${below.small`
      div {
        max-height: 200px;
      }
      max-height: 200px;
    `}
  }
  .content-section {
    padding: 0 var(--basePadding);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    min-height: 250px;
    ${below.large`
      min-height: 300px;
    `}
    ${below.mediumLarge`
      min-height: 300px;
    `}
    @media (max-width: 767px) {
      min-height: 350px;
    }
    ${below.small`
      min-height: 215px;
    `}
    ${below.xSmall`
      min-height: 250px;
    `}
  }
`
