import { getGAClientId, getKlaviyoId, getRachioAccountEmail, getRachioAccountId } from "analytics"
import { AttributeInput } from "lib/shopify/storefront-api-client/types/storefront.types";

export const getReferrerCartAttributes = () => {
  let referrer = document.referrer;
  let currentDomain = window.location.hostname;

  if (referrer) {
      let referrerDomain = new URL(referrer).hostname;

      // Check if the referrer is external (not from the same domain)
      if (referrerDomain !== currentDomain) {
        return referrer;
      }
  }
}

export const cartCustomAttributes = (newAttributes?: AttributeInput[]): AttributeInput[] => {
  const gaClientId = getGAClientId()
  const rachioAccountEmail = getRachioAccountEmail()
  const klaviyoId = getKlaviyoId()
  const rachioAccountId = getRachioAccountId()
  
  let attrs = [
    {
      key: 'ga_client_id',
      value: gaClientId,
    }
  ] as AttributeInput[]

  if (rachioAccountEmail) {
    attrs.push({
      key: 'rachio_account_email',
      value: rachioAccountEmail
    })
  }

  if (klaviyoId) {
    attrs.push({
      key: 'klaviyo_id',
      value: klaviyoId
    })
  }

  if (rachioAccountId) {
    attrs.push({
      key: 'rachio_account_id',
      value: rachioAccountId
    })
  }

  if (newAttributes) {
    attrs = [...attrs, ...newAttributes];
  }

  return attrs.filter(({ value }) => value)
}