import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { below } from 'styles'
import { ProductCopy } from 'types/misc'
import { VariantSelector } from '../VariantSelector'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Price } from '../Price'
import { GatsbyShopifyStorefrontProductLoader } from '../loaders'
import { ProductUpsellInfoModal } from './ProductUpsellInfoModal'
import { GatsbyShopifyStorefrontCopyUpsellProduct, ShopifyProductVariant } from 'lib/shopify/storefront-api-client/types/custom.types'
import { getDefaultShopifyProductImage } from 'utilities'

type FreeGiftProps = {
  product: GatsbyShopifyStorefrontCopyUpsellProduct |  null
  altTitle?: string
  setSelectedVariant: (variant: ShopifyProductVariant) => void
  selectedVariant: ShopifyProductVariant
  onClick: (product: GatsbyShopifyStorefrontCopyUpsellProduct) => void
}

const FreeGift = ({
  product,
  selectedVariant,
  altTitle,
  setSelectedVariant,
  onClick
}: FreeGiftProps) => {
  if (!product) return null;

  useEffect(() => {
    setSelectedVariant(product.variants[0])
  }, [])

  if (!selectedVariant) return null;

  const productImage = getDefaultShopifyProductImage(product);

  return (
    <ItemStyles>
        <div className="upsell-content">
          <div className="image-container">
            <div className='image-container'>
              <button onClick={() => onClick(product)}>
                <GatsbyImage
                  image={productImage.image.gatsbyImageData}
                  alt={product.title}
                />
              </button>
            </div>
          </div>
          <div className="heading-content">
            <div>
              <button 
                onClick={() => onClick(product)}
                className='underline'
              >
                {altTitle || product.title}
              </button>
              {
                product.variants.length > 1 ? (
                  <VariantSelector 
                    variants={product.variants}
                    setSelectedVariant={(variant) => {
                      setSelectedVariant(variant)
                    }}
                    selectedVariant={selectedVariant}
                    hidePrice={true}
                    type='input'
                  />
                ) : null
              }
            </div>
            
            <div className="no-margins h5">
              <span className='sr-only'>Current Price</span>
              <Price 
                basePrice={Number(selectedVariant.price.amount)}
                type="tiny"
                discountAmount={Number(selectedVariant.price.amount)}
              />
            </div>
          </div>
        </div>
    </ItemStyles>
  )
}

type Props = {
  productPage: ProductCopy
  setSelectedVariant: (variant: ShopifyProductVariant) => void
  selectedVariant?: ShopifyProductVariant | null
}
export const FreeGiftUpsell = ({
  productPage,
  selectedVariant,
  setSelectedVariant
}: Props) => {
  const [modalProduct, setModalProduct] = useState<GatsbyShopifyStorefrontCopyUpsellProduct | null>(null);
  return (
    <>
      <GatsbyShopifyStorefrontProductLoader
        productPage={productPage.freeGift.product}
        render={(product) => {
          if (!product || !product.availableForSale) return null;

          const freeGiftProduct = {
            ...product,
            variants: productPage.freeGift.giftVariantSku 
            ? product.variants.filter(v => v.sku === productPage.freeGift.giftVariantSku)
            : product.variants,
          } as GatsbyShopifyStorefrontCopyUpsellProduct;

          return (
            <>
              <ProductUpsellInfoModal
                onClose={() => setModalProduct(null)}
                isActive={Boolean(modalProduct)}
                product={modalProduct}
              />
              <FreeGiftWrapper>
              {productPage.freeGift.description && (
                <h4>{productPage.freeGift.description}</h4>
              )}
                <FreeGift
                  product={freeGiftProduct}
                  selectedVariant={selectedVariant}
                  altTitle={productPage.freeGift.title}
                  setSelectedVariant={setSelectedVariant}
                  onClick={setModalProduct}
                /> 
              </FreeGiftWrapper>
            </>
          )
        }}
      />
    </>
  )
}

const FreeGiftWrapper = styled.div`
  background: white;
  padding: 1.3rem;
  margin-bottom: 1.3rem;
  border: 1px solid var(--lineColor);
  border-radius: 20px;
  text-align: center;
  h4 {
    font-size: 1.6rem;
    margin: 0;
    max-width: 400px;
    margin: 0 auto;
  }
`

const ItemStyles = styled.div`
  .upsell-content {
    display: flex;
    position: relative;    
    margin: 1rem auto;
    border-radius: var(--cardRadius);
    border: 1px solid var(--lineColor);
    padding: 1rem;
    .heading-content {
      width: 100%;
      padding: 0 1rem;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: space-between;

      ${below.small`
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 1rem 0;
      `}
    }
  }

  .variant-select-input {
    border-radius: 25px;
    padding: 2px 10px;
    font-size: 13px;
    margin: 0;
    ${below.small`
      margin: .5rem 0;
    `}
  }

  .image-container {
    isolation: isolate;
    border-radius: 1rem;
    overflow: hidden; 
    width: 8rem;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
  }

  .price-text {
    display: flex;
  }
`
