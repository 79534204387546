import React from 'react'
import { SectionTheme, SectionSize } from 'types/misc'
import { SanitySection } from './SanitySection'
import { sizes } from 'styles'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Button } from 'components/elements'

type Props = {
  title: string
  description: string
  buttonText: string
  buttonLink: string
  id?: string
  theme?: SectionTheme | ''
  size?: SectionSize | ''
}

function SanityControllerRebatesFormSection({
  id = '',
  title,
  description,
  size = '',
  buttonText = '',
  buttonLink = ''
}: Props) {
  return (
    <Container>
      <SanitySection
        size={size}
        width={`${sizes.medium}px`}
        id={id}
      >
        <Header>
          <h2>{title}</h2>
          <p>{description}</p>
        </Header>
        <div className="center-text">
          <Button as={Link} to={buttonLink}>
            {buttonText}
          </Button>
        </div>
      </SanitySection>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  background: var(--white);
  overflow: hidden;

  form {
    margin: 0 auto;
    max-width: 768px;
  }
`

const Header = styled.div`
  h2 {
    margin-top: 0;
  }
  text-align: center;
  p {
    max-width: 600px;
    margin: 0 auto 3rem;
  }
`

export default SanityControllerRebatesFormSection
