import { Button, Icon } from "components/elements";
import { Link } from "gatsby";
import React, { ReactNode } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { Wrapper, below, sizes } from "styles";
import { SectionOuter } from "./sectionStyle";

type SlickSlider = {
  slickNext: () => any
  slickPrev: () => any
}

type Props = {
  children: ReactNode,
  id: string,
  buttonText?: string,
  buttonLink?: string,
  headline: string,
  description?: string
  settings?: SlickSettings
  hideButtonOnMobile?: boolean
}

type SlickSettings = {
  dots?: boolean,
  arrows?: boolean,
  infinite?: boolean,
  speed?: number,
  slidesToShow?: number,
  slidesToScroll?: number,
  centerMode?: boolean,
  responsive?: {
    breakpoint: number,
    settings: {
      dots?: boolean,
      arrows?: boolean,
      infinite?: boolean,
      speed?: number,
      slidesToShow?: number,
      slidesToScroll?: number,
      centerMode?: boolean,
    }
  }[]
}

const SliderSection = ({ 
  id, 
  buttonText, 
  children, 
  headline, 
  description, 
  buttonLink, 
  settings, 
  hideButtonOnMobile = true 
}: Props) => {
  const slider = React.useRef<SlickSlider | null>(null);
  const nextSlide = () => slider?.current?.slickNext();
  const prevSlide = () => slider?.current?.slickPrev();

  const defaultSettings = {
    accessibility: true,
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: true,
    nextArrow: <Icon name="arrow-right" className="slider-next" onClick={nextSlide} accent='var(--lightGray)' />,
    prevArrow: <Icon name="arrow-left" className="slider-prev" onClick={prevSlide} accent='var(--lightGray)' />,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: sizes.mediumLarge,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: sizes.small,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  }
  const slickSettings = { ...defaultSettings, ...settings };

  return (
    <SliderSectionContainer
      id={`slider-section-${id}`}
      className="Section Section--theme__white Section--yard-reviews"
    >
      <Wrapper>
        <SectionHeader className={hideButtonOnMobile ? 'hide-button-on-mobile' : ''}>
          <div className="text-wrapper center-text">
            <h2>{headline}</h2>
            {description && <p>{description}</p>}
          </div>
          
          
          <div className="button-wrapper">
            {
              buttonLink && (
                <Button
                  as={Link}
                  className="btn--next-slide"
                  to={buttonLink || ''}
                >
                  {buttonText || 'Learn more'}
                </Button>
              )
            }
          </div>
        </SectionHeader>
      </Wrapper>
      <SliderStyles>
        <Slider {...slickSettings} ref={slider}>
          {children}
        </Slider>
      </SliderStyles>
      
    </SliderSectionContainer>
  )
}

export const SliderStyles = styled.div`
  .slick-prev, .slick-next {
    top: 50%;
    width: 69px;
    height: 70px;
    z-index: 1;
    background: rgba(255,255,255,.8);
    padding: 0 20px;
    box-shadow: var(--elevation-1);
    transition: var(--transition);
    &:hover {
      background: rgba(255,255,255,.8);
      box-shadow: var(--elevation-2);
    }
    ${below.medium`
      width: 50px;
      height: 60px;
      padding: 0px 14px;
    `}
  }
  .slick-prev:before, .slick-next:before {
    font-size: 50px;
    color: var(--lightGray);
    opacity: 1;
    ${below.medium`
      font-size: 30px;
    `}
  }
  .slick-next {
    right: 0px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .slick-prev {
    left: 0px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`

const SliderSectionContainer = styled(SectionOuter)`
  padding: var(--bottomSectionPadding) 0 var(--bottomSectionPadding) 0;
  .card-review {
    width: 200px;
    margin-left: 20px;
    margin-right: 20px;
  }
`

const SectionHeader = styled.div`
  margin: 0 0 2rem;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2, p {
    margin: 0;
  }
  h2 {
    font-weight: 500;
    margin-bottom: 2rem;
  }

  ${below.mediumLarge`
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;

    .button-wrapper {
      margin-top: 2rem; 
    }

    &.hide-button-on-mobile .button-wrapper {
      display: none;
    }
    
    .text-wrapper {
      text-align: center;
      h2 {
        font-size: calc(var(--h2) * .75);
      }
      width: 100%;
    }
  `}
`

export default SliderSection