import styled from "styled-components";

export const AddToCartText = styled.div<{ color?: string, noTopMargin?: boolean}>`
  text-align: center;

  *, p, a {
    color: ${props => props.color || 'inherit'};
  }

  p:first-child {
    margin-top: ${props => props.noTopMargin ? '0' : 'revert'};
  }
`