import React from 'react'
import styled, { css } from 'styled-components'

type Props = {
  height?: string
  style?: object
  light?: boolean
}

const LogoWrapper = styled.span`
  ${({ light } : { light: boolean }) => (
    light ? css`
      .cls-2 {
        fill: white;
      }
    ` : null
  )}
`

type LogoProps = {
  height: string,
  style: {}
}
const StandardLogo = ({ height, style }: LogoProps) => {
  return (
    <svg 
      viewBox="0 0 200 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
      height,
      ...style,
      }}
    >
      <title>Rachio Logo</title>
      <path d="M23.8712 51.907C20.9409 51.907 18.5667 49.5328 18.5667 46.6025V46.3072H23.8712V51.907Z" fill="#21A8FF"/>
      <path d="M23.8712 37.1727V42.7724H12.7916C11.5849 42.7724 10.6089 41.7941 10.6089 40.5897V37.1727H23.8712Z" fill="#21A8FF" />
      <path d="M18.5667 28.0358C21.497 28.0358 23.8712 30.41 23.8712 33.3403V33.6356H2.18269C0.975983 33.6356 0 32.6573 0 31.4529V28.0358H18.5667Z" fill="#21A8FF"/>
      <path d="M5.30445 18.5667H5.59978V23.8712H0C0 20.9409 2.3742 18.5667 5.30445 18.5667Z" fill="#45C371"/>
      <path d="M9.13685 12.7916C9.13685 11.5849 10.1151 10.6089 11.3195 10.6089H14.7366V23.8712H9.13685V12.7916Z" fill="#45C371"/>
      <path d="M18.5667 23.8712H18.2714V2.18269C18.2714 0.975983 19.2497 0 20.4541 0H23.8712V18.5667C23.8712 21.497 21.497 23.8712 18.5667 23.8712Z" fill="#45C371"/>
      <path d="M33.3403 5.30445V5.59978H28.0358V0C30.9661 0 33.3403 2.3742 33.3403 5.30445Z" fill="#21A8FF"/>
      <path d="M39.1154 9.13685C40.3221 9.13685 41.2981 10.1151 41.2981 11.3195V14.7366H28.0358V9.13685H39.1154Z" fill="#21A8FF"/>
      <path d="M49.7243 18.2714C50.931 18.2714 51.907 19.2497 51.907 20.4541V23.8712H33.3403C30.41 23.8712 28.0358 21.497 28.0358 18.5667V18.2714H49.7243Z" fill="#21A8FF"/>
      <path d="M46.6025 33.3403H46.3072V28.0358H51.907C51.907 30.9661 49.5328 33.3403 46.6025 33.3403Z" fill="#45C371"/>
      <path d="M42.7724 39.1154C42.7724 40.3221 41.7942 41.2981 40.5898 41.2981H37.1727V28.0358H42.7724V39.1154Z" fill="#45C371"/>
      <path d="M33.3403 28.0358H33.6356V49.7243C33.6356 50.931 32.6573 51.907 31.4529 51.907H28.0358V33.3403C28.0358 30.41 30.41 28.0358 33.3403 28.0358Z" fill="#45C371"/>
      <path d="M63.0258 12.7454H69.0478V17.3646C70.3445 14.2498 73.5631 12.3809 77.7693 12.3809H78.7038V18.1445H76.731C72.1119 18.1445 69.0478 21.0517 69.0478 27.2813V38.9608H63.0258V12.7454Z" fill="#00202C" className="cls-2" />
      <path d="M79.479 25.8277C79.479 18.0937 85.3972 12.1225 92.9766 12.1225C96.6614 12.1225 99.8293 13.5761 102.113 16.0149L102.06 12.6924L108.082 12.7431V38.9585H102.06L102.113 35.6891C99.8293 38.024 96.6614 39.5815 92.9766 39.5815C85.3972 39.5815 79.479 33.4556 79.479 25.8254V25.8277ZM85.501 25.8277C85.501 30.5 89.2388 34.2886 93.8073 34.2886C98.3757 34.2886 102.113 30.4977 102.113 25.8277C102.113 21.1578 98.4264 17.4177 93.8073 17.4177C89.1881 17.4177 85.501 21.1024 85.501 25.8277Z" fill="#00202C" className="cls-2" />
      <path d="M123.567 12.1225C129.12 12.1225 133.273 14.822 135.611 18.8205L130.938 21.467C129.642 19.0259 126.993 17.3139 123.724 17.3139C119.104 17.3139 115.417 21.1555 115.417 25.8277C115.417 30.5 119.102 34.3416 123.724 34.3416C126.889 34.3416 129.589 32.6804 130.888 30.2416L135.768 32.888C133.534 36.6766 129.226 39.5838 123.62 39.5838C115.678 39.5838 109.395 33.4579 109.395 25.7747C109.395 18.0914 115.676 12.1225 123.567 12.1225Z" fill="#00202C" className="cls-2" />
      <path d="M137.233 38.9608V2.10424H143.255V15.1335C145.07 13.2115 147.615 12.0717 150.781 12.0717C157.011 12.0717 161.579 16.4325 161.579 22.6621V38.9631H155.557V23.8573C155.557 19.8611 153.169 17.42 149.482 17.42C145.795 17.42 143.252 20.0157 143.252 24.3234V38.9631H137.23L137.233 38.9608Z" fill="#00202C" className="cls-2"/>
      <path d="M166.92 1.58511C169.154 1.58511 170.658 3.08946 170.658 5.21908C170.658 7.3487 169.152 8.85305 166.92 8.85305C164.689 8.85305 163.183 7.34639 163.183 5.21908C163.183 3.09176 164.791 1.58511 166.92 1.58511ZM169.931 12.7454V38.9608H163.909V12.7454H169.931Z" fill="#00202C" className="cls-2" />
      <path d="M185.829 12.1225C193.72 12.1225 200 18.1445 200 25.7747C200 33.4049 193.72 39.5838 185.829 39.5838C177.938 39.5838 171.604 33.4579 171.604 25.7747C171.604 18.0914 177.885 12.1225 185.829 12.1225ZM185.829 34.187C190.397 34.187 194.031 30.5 194.031 25.83C194.031 21.1601 190.397 17.4731 185.829 17.4731C181.26 17.4731 177.576 21.1578 177.576 25.83C177.576 30.5023 181.209 34.187 185.829 34.187Z" fill="#00202C" className="cls-2" />
    </svg>
  )
}

export const Logo = ({ height = '30px', style = {}, light = false }: Props) => (
  <LogoWrapper light={light}>
    <StandardLogo height={height} style={style} />
  </LogoWrapper>
)
