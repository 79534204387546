import { EmailInput } from "components/inputs";
import React from "react";
import { subscribeToKlaviyoList } from "../../lib/klaviyo";
import styled from "styled-components";
import { getProductModalDisplayName, getProductWaitingListIdFromKlaviyo } from "utilities";

type Props = {
  productPage: { 
    klaviyoBackInStockListId?: string, 
    handle: string,
    backInStockTitle?: string
    backInStockDescription?: string
    backInStockButtonText?: string
  },
  product: { 
    title: string 
  },
  selectedVariant: { 
    title: string 
  }
}
export const BackInStockAlert = ({ productPage, product, selectedVariant }: Props) => {
  const onSubmit = async (email: string) => {
    subscribeToKlaviyoList(
      email,
      getProductWaitingListIdFromKlaviyo(productPage.klaviyoBackInStockListId)
    )
  }

  const title = (
    productPage.backInStockTitle 
    || `${getProductModalDisplayName(productPage, product, selectedVariant)} will be available again soon`
  )

  const description = (
    productPage.backInStockDescription 
    || 'Please enter your email below and we will notify you when this product is back in stock.'
  )

  return (
    <OutOfStockAlert className="out-of-stock-alert">
      <h5 className='no-top-margin'>{title}</h5>
      <p>{description}</p>
      <EmailInput
        id="newsletter-submit"
        confirmationText="Thanks for signing up!"
        onSubmit={onSubmit}
        buttonText={productPage.backInStockButtonText}
      />
    </OutOfStockAlert>
  )    
}

export const OutOfStockAlert = styled.div`
  margin-bottom: var(--margin);
  background: white;
  padding: var(--cardPadding);
  border-radius: var(--cardRadius);
  text-align: center;
  h5 {
    font-size: var(--h4);
    margin-bottom: 0;
  }
  p {
    margin: 10px 0;
  }
`
