import { Accordion, PortableText } from "components/elements";
import React from "react";
import { Section } from "./Section";
import { Wrapper } from "styles";
import { SectionPaddingSize, SectionSize, SectionTheme } from "types/misc";
import styled from "styled-components";

type Props = {
  borderTop?: boolean  
  title: string
  description?: string
  questions: {
    title: string,
    id: string
    _rawDescription: any[]
  }[]
  size?: SectionSize,
  paddingTop?: SectionPaddingSize
  paddingBottom?: SectionPaddingSize
  theme: SectionTheme
}
const SanityFAQSection = ({ 
  borderTop, 
  title,
  description, 
  questions, 
  size, 
  paddingTop, 
  paddingBottom,
  theme  
}: Props) => {
  const whiteTextThemes = ['green', 'primary', 'secondary']
  return (
    <div className={`theme-${theme}`} style={{ borderTop: borderTop ? '1px solid var(--lineColor)' : 'none' }}>
      <Section 
        size={size || 'small'} 
        paddingBottom={paddingBottom} 
        paddingTop={paddingTop} 
        width='tight'
        theme={theme}
      >
        <Wrapper>
          <h2 className="center-text no-top-margin">{title}</h2>
          {description && <p>{description}</p>}
          {questions.map((item, index) => (
            <Accordion 
              title={item.title}
              key={`${index}-${item.id}`} 
              arrowColor={whiteTextThemes.includes(theme) ? 'white' : 'black'}
            >
              <PortableText _rawContent={item._rawDescription} />
            </Accordion>
          ))}
        </Wrapper>
      </Section>
    </div>
  )
}

export default SanityFAQSection