import { Button } from "@rachio-npm/rachio-retail-common";
import { Extend, TrackingProductUnformatted, trackProductViewed } from "analytics";
import { LineItemInput, useCart, useLoad } from "global";
import { usePrevious, useGaCheck, useProductVariantSelection } from "hooks";
import React, { useState } from "react";
import { filterVariantsBySku } from "../../../lib/shopify";
import { determineBuyButtonColor, determineProductAvailabilityStatus, ProductAvailabilityStatus, determineBuyButtonText, fetchIdFromGlobalId, isFreeGiftApplied, isTieredDiscountApplied, isServiceProduct, isVariantInCart } from "utilities";
import Affirm from "../Affirm";
import { BackInStockAlert } from "../BackInStockAlert";
import { QuantitySelector } from "../QuantitySelector";
import { VariantSelector } from "../VariantSelector";
import { BundleUpsell, FreeGiftUpsell, ProductUpsellMultiSelect, ProductUpsellSelections } from "../upsells";
import { ProductCopy } from "types/misc";
import { ModalUpsell } from "../upsells/ModalUpsell";
import { BuyWithAmazonButton } from "../BuyWithAmazonButton";
import { CONTROLLER_PRODUCT_HANDLES } from "../../../../common/config/products"
import { ShopInternationalButton } from "../ShopInternationalButton";
import { ShopifyProduct, ShopifyProductVariant } from "lib/shopify/storefront-api-client/types/custom.types";
import { PortableText } from "components/elements";
import { AddToCartText } from "./styles";

type Props = {
  product: ShopifyProduct
  productPage: ProductCopy
  addToCartTestId?: string
  onSelectedVariant?: (variant: ShopifyProductVariant) => any
}

export const ProductForm = ({ 
  product, 
  productPage, 
  addToCartTestId,
  onSelectedVariant
}: Props) => {

  const variantIdx = filterVariantsBySku(product.variants, productPage.filterSku);
  const [selectedVariant, setSelectedVariant] = useState<ShopifyProductVariant>(
    product.variants[variantIdx]
  )
  const variants = productPage.filterSku ? [product.variants[variantIdx]] : product.variants
  const { cart, addVariantsToCart } = useCart()
  const { isLoading } = useLoad()
  const [currentQuantity, setCurrentQuantity] = useState<number>(1);
  const previousSelectedVariant = usePrevious(product.variants[variantIdx]);
  const [upsellModalActive, setUpsellModalActive] = useState<boolean>(false);
  const [upsellModalOutOfStock, setUpsellModalOutOfStock] = useState<boolean>(false);
  const [upsellSelections, setUpsellSelections] = useState<ProductUpsellSelections | null>(null)
  const [giftVariant, setGiftVariant] = useState<ShopifyProductVariant | null>()
  const isServiceProductInCart = isServiceProduct(product) && isVariantInCart(selectedVariant.id, cart);
  const strippedProductId = fetchIdFromGlobalId(selectedVariant.id)
  

  useGaCheck(() => {
    if (previousSelectedVariant?.id !== selectedVariant.id) {
      const trackingProduct: TrackingProductUnformatted = {
        ...selectedVariant,
        productId: String(product.id),
        variantId: String(selectedVariant.id),
        price: selectedVariant.price.amount
      };

      trackProductViewed(trackingProduct)
    }
  }, [selectedVariant]);

  const handleSelectedVariant = (variant) => {
    setSelectedVariant(variant);
    if (typeof onSelectedVariant === 'function') onSelectedVariant(variant)
  }

  useProductVariantSelection({
    productPage,
    product,
    setSelectedVariant: handleSelectedVariant
  })

  const deriveLineItems = () => {
    let lineItemsToAdd: LineItemInput[] = [
      {
        variantId: String(selectedVariant.id),
        quantity: currentQuantity,
        name: product.title,
        price: selectedVariant.price.amount,
        sku: selectedVariant.sku,
      }
    ]

    if (upsellSelections?.length) {
      lineItemsToAdd = [
        ...lineItemsToAdd,
        ...upsellSelections
          .filter(selection => selection.isSelected && selection.variant.availableForSale)
          .map(({ variant }) => ({
          variantId: String(variant.id),
          quantity: 1,
          name: variant.title,
          price: variant.price.amount,
          sku: variant.sku,
          customAttributes: [{
            key: 'Product',
            value: selectedVariant.title
          }]
        })) 
      ]
    }

    if (
      productPage.freeGiftEnabled 
      && giftVariant 
      && productPage.freeGift.discountCode
      && !isTieredDiscountApplied(cart)
    ) {
      lineItemsToAdd = [
        ...lineItemsToAdd,
        {
          variantId: String(giftVariant.id),
          quantity: 1,
          name: giftVariant.title,
          price: giftVariant.price.amount,
          sku: giftVariant.sku,
          customAttributes: [
            { 
              key: 'FreeGift', 
              value: selectedVariant.title
            }
          ]
        }
      ] 
    }

    return lineItemsToAdd;
  }

  const deriveDiscountCodes = () => {
    return productPage.freeGift?.discountCode 
      ? [productPage.freeGift?.discountCode] 
      : undefined
  }

  const addToCart = async () => {
    const canAddModalUpsell = 
      productPage.upsellModalEnabled 
      && !upsellModalOutOfStock 
      && !productPage.extendEnabled
      && !productPage.productUpsellListEnabled;
  

    await addVariantsToCart(
      deriveLineItems(),
      canAddModalUpsell, 
      deriveDiscountCodes()
    )

    if (canAddModalUpsell) {
      setUpsellModalActive(true);
    }
  }

  return (
    <>
      {productPage.upsellModalEnabled && productPage.upsellModalProduct && (
        <ModalUpsell 
          productPage={productPage.upsellModalProduct}
          bannerText={productPage.upsellModalBannerText}
          isModalActive={upsellModalActive}
          onOutOfStock={() => setUpsellModalOutOfStock(true)}
        />
      )}

      <div className='product-form'>
        <VariantSelector
          variants={variants}
          setSelectedVariant={handleSelectedVariant}
          selectedVariant={selectedVariant}
          type={productPage.variantSelector}
          hidePrice={productPage.hideVariantPrices}
          variantDescription={productPage.variantSelectorDescription}
          testId='product-page-variant-selector'
        />

        {productPage.bundleUpsellProduct && productPage.bundleUpsellCtaText && (
          <BundleUpsell 
            bundleUpsellCtaText={productPage.bundleUpsellCtaText}
            bundleUpsellCtaButtonText={productPage.bundleUpsellCtaButtonText}
            bundleUpsellNavigateToBundlePage={productPage.bundleUpsellNavigateToBundlePage}
            bundleUpsellProduct={productPage.bundleUpsellProduct}
          />
        )}

        {productPage.extendEnabled && (
          <Extend
            strippedProductId={strippedProductId}
            title={selectedVariant.title}
            loadJustSdk={true}
          />
        )}

        {productPage.quantitySelectionEnabled && (
          <QuantitySelector
            selectedVariant={selectedVariant}
            currentQuantity={currentQuantity}
            setCurrentQuantity={setCurrentQuantity}
            upsellText={productPage.quantitySelectionUpsellText}
          />
        )}

        {productPage._rawTopAddToCartText && (
          <AddToCartText color={productPage.topAddToCartTextColor?.hex} noTopMargin>
            <PortableText _rawContent={productPage._rawTopAddToCartText} />
          </AddToCartText>
        )}

        <div className='buy-buttons'>
          <Button
            className="buy-button"
            color={determineBuyButtonColor(
              determineProductAvailabilityStatus(selectedVariant)
            )}
            data-testid={
              selectedVariant.availableForSale 
              ? (addToCartTestId || 'product-overview-buy-button')
              : 'product-overview-out-of-stock-button'
            }
            style={{ width: '100%' }}
            disabled={
              !selectedVariant.availableForSale ||
              isLoading ||
              !cart ||
              determineProductAvailabilityStatus(selectedVariant) === ProductAvailabilityStatus.Unavailable
              || isServiceProductInCart
            }
            onClick={addToCart}
          >
            {
              determineProductAvailabilityStatus(selectedVariant) === ProductAvailabilityStatus.Unavailable && productPage.outOfStockButtonText 
              ? productPage.outOfStockButtonText
              : determineBuyButtonText(determineProductAvailabilityStatus(selectedVariant))
            }
          </Button>
          
          <ShopInternationalButton />

          {productPage.amazonUrl && (
            <BuyWithAmazonButton 
              selectedVariant={selectedVariant} 
              url={productPage.amazonUrl}
              isController={productPage.handle === CONTROLLER_PRODUCT_HANDLES.RACHIO_THREE}
              buttonText={productPage.amazonUrlText}
            />
          )}
        </div>
        
        {productPage._rawBottomAddToCartText && (
          <AddToCartText color={productPage.bottomAddToCartTextColor?.hex}>
            <PortableText _rawContent={productPage._rawBottomAddToCartText} />
          </AddToCartText>
        )}

        {!selectedVariant.availableForSale && productPage.backInStockEnabled && productPage.klaviyoBackInStockListId && (
          <BackInStockAlert 
            productPage={productPage}
            product={product}
            selectedVariant={selectedVariant}
          />
        )}

        {productPage.affirmEnabled && selectedVariant.availableForSale && <Affirm variant={selectedVariant} />}
      </div>
      {
        selectedVariant.availableForSale &&
        productPage.productUpsellListEnabled &&
        productPage.productUpsellList?.length &&
        !productPage.upsellModalEnabled &&
        (  
          <ProductUpsellMultiSelect
            productUpsells={productPage.productUpsellList}
            onChange={setUpsellSelections}
            title={productPage.productUpsellListTitle}
          /> 
        )
      }

      {
        cart &&
        selectedVariant.availableForSale &&
        productPage.freeGiftEnabled &&
        !productPage.productUpsellListEnabled &&
        !isFreeGiftApplied(cart) && (
          <FreeGiftUpsell 
            productPage={productPage}
            selectedVariant={giftVariant}
            setSelectedVariant={setGiftVariant}
          />
        )
      }
    </>
  )
}