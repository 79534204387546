import React, { useState } from 'react'
import styled from 'styled-components'
import loadable from '@loadable/component'
const CustomGatsbyImage = loadable(() => import('components/elements'), {
  resolveComponent: (components) => components.CustomGatsbyImage,
})
import { IGatsbyImageData } from 'gatsby-plugin-image'

import {
  SANITY_MOBILE_RESPONSIVE_IMAGE_BREAKPOINT,
  ProductCopy,
  RowDropdownImage,
} from 'types/misc'

import { useWindowWidth } from 'hooks'
import { ProductBadgeSimple } from 'components/product/ProductBadge'
import { SanitySplitLayout, SanitySplitProps } from './SanitySplitLayout'

type ImageContainerProps = {
  image: {
    gatsbyImageData: IGatsbyImageData
  }
  mobileImage?: {
    gatsbyImageData: IGatsbyImageData
  }
  alt: string
  imageHasGrayBackground: boolean,
  productPage?: ProductCopy
}
const ImageContainer = ({
  image,
  mobileImage,
  alt,
  imageHasGrayBackground,
  productPage
}: ImageContainerProps) => {
  const width = useWindowWidth();
  const primaryImage = mobileImage && Number(width) <= SANITY_MOBILE_RESPONSIVE_IMAGE_BREAKPOINT
    ? mobileImage
    : image;

  const ratio = primaryImage.gatsbyImageData.width / primaryImage.gatsbyImageData.height;

  return (
    <div
      className={`image-container ${imageHasGrayBackground ? 'gray-bg' : ''}`}
    >
      {productPage?.badgeEnabled && (
        <ProductBagdgeWrapper>
          <ProductBadgeSimple 
            productPage={productPage} 
          />
        </ProductBagdgeWrapper>
      )}
      <CustomGatsbyImage
        style={{ height: '100%' }}
        image={primaryImage.gatsbyImageData}
        alt={alt}
        objectFit={ratio > .80 ? 'fill' : 'contain'}
      />
    </div>
  );
}

type SanitySplitImageProps = {
  image: {
    gatsbyImageData: IGatsbyImageData
  }
  mobileImage?: {
    gatsbyImageData: IGatsbyImageData
  }
  imageHasGrayBackground?: boolean
  alt: string
  onDropdownClicked?: (idx: number, imageAsset: RowDropdownImage) => void
} & SanitySplitProps

export const SanitySplit = ({
  theme = '',
  title = '',
  subtitle = '',
  isMobileReverse = false,
  isSplitLayout = true,
  hasComplexList = false,
  isMediaFirst,
  list,
  listBulletsType,
  listHasMargin = false,
  listHasAccentText = false,
  rowReference,
  _rawParagraph,
  alt,
  size = '',
  paddingTop = '',
  paddingBottom = '',
  id = '',
  buttonLink = '',
  buttonText = '',
  buttonTheme = '',
  textAlign = 'left',
  addToCartEnabled,
  productPage,
  noRedirectToCart,
  goToCheckout,
  quantitySelectionEnabled,
  image, 
  mobileImage, 
  imageHasGrayBackground 
}: SanitySplitImageProps) => {
  const [currentImage, setCurrentImage] = useState<RowDropdownImage>({
    image, 
    mobileImage
  })
  const nodeImg = (
    <ImageContainer
      image={currentImage.image}
      mobileImage={currentImage.mobileImage}
      imageHasGrayBackground={imageHasGrayBackground || false}
      alt={alt}
      productPage={productPage}
    />
  )

  const onDropdownClicked = (_, imageAsset) => {
    if (imageAsset) setCurrentImage(imageAsset);
  }

  return (
    <SanitySplitLayout
      id={id}
      size={size}
      theme={theme}
      title={title}
      subtitle={subtitle}
      isMediaFirst={isMediaFirst}
      hasComplexList={hasComplexList}
      mediaItem={nodeImg}
      list={list}
      listBulletsType={listBulletsType}
      listHasMargin={listHasMargin}
      listHasAccentText={listHasAccentText}
      rowReference={rowReference}
      alt={alt}
      _rawParagraph={_rawParagraph}
      isMobileReverse={isMobileReverse}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      buttonLink={!addToCartEnabled ? buttonLink : ''}
      buttonText={buttonText}
      buttonTheme={buttonTheme}
      isSplitLayout={isSplitLayout}
      textAlign={textAlign}
      addToCartEnabled={addToCartEnabled}
      goToCheckout={goToCheckout}
      productPage={productPage}
      noRedirectToCart={noRedirectToCart}
      quantitySelectionEnabled={quantitySelectionEnabled}
      onDropdownClicked={onDropdownClicked}
    />
  )
}

const ProductBagdgeWrapper = styled.div`
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: 2;
  .product-badge {
    font-size: 1.5rem;
    padding: 8px 13px;
  }
`