import { identify, trackEvent, TrackFunction } from "analytics"

enum NEWSLETTER_SIGN_UP_EVENT_NAMES {
  SIGN_UP = 'Newsletter Sign Up'
}

type NewsletterEventInputs = {
  [NEWSLETTER_SIGN_UP_EVENT_NAMES.SIGN_UP]: {
    type: string,
  }
}

const track: TrackFunction<NEWSLETTER_SIGN_UP_EVENT_NAMES.SIGN_UP, NewsletterEventInputs[NEWSLETTER_SIGN_UP_EVENT_NAMES.SIGN_UP]> = async (
  eventName,
  properties
) => {
  await trackEvent(eventName, properties)
}

export const trackNewsletterSignup = async (
  email: string,
  type?: string
) => {
  identify(email); 
  await track(NEWSLETTER_SIGN_UP_EVENT_NAMES.SIGN_UP, {
    type: type || 'newsletter_signup'
  })
}