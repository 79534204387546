import React, { ReactNode } from 'react'
import loadable from '@loadable/component'
import { WindowLocation } from '@reach/router'
import { ModalProvider, BaseModalBackground } from 'styled-react-modal'
import styled from 'styled-components'

const MainLayout = loadable(() => import('./MainLayout'))
const Header = loadable(() => import('components/Header/Header'))
const ContextProvider = loadable(() => import('global'), {
  resolveComponent: (components) => components.ContextProvider,
})
const ProductContextProvider = loadable(() => import('global'), {
  resolveComponent: (components) => components.ProductContextProvider,
})
const Footer = loadable(() => import('components/Footer'), {
  resolveComponent: (components) => components.Footer,
})
import { CartProvider, IpGeolocationProvider, NotificationProvider } from 'global'
import { Notification } from 'components/elements'
import 'styles/css/index.css' // https://www.gatsbyjs.com/docs/how-to/styling/using-local-fonts/
import { isBrowser } from 'utilities'
import { CookieBanner } from 'components/banners/CookieBanner'

type Props = {
  location: WindowLocation
  pageContext: {
    layout?: 'default' | 'stripped', 
  }
  children?: ReactNode
}

const Page = ({ children, pageContext }: Props) => {
  if (!isBrowser) return null;

  if (location.pathname === '/') {
    return (
      <ContextProvider>
        <ModalProvider backgroundComponent={SpecialModalBackground}>
          <CookieBanner />
          <Header
            isNavShowing={!location.pathname.includes('extended-warranty')}
            isCartPage={location.pathname.startsWith('/cart')}
          />
          <MainLayout location={location}>{children}</MainLayout>
          <Footer/>
        </ModalProvider>
      </ContextProvider>
    )
  }

  if (location.pathname.startsWith('/cart') || location.pathname.includes('/products/')) {
    return (
      <ContextProvider>
        <ProductContextProvider>
          <ModalProvider backgroundComponent={SpecialModalBackground}>
            <CookieBanner />
            <Header
              isNavShowing={!location.pathname.startsWith('/extended-warranty')}
              isCartPage={location.pathname.startsWith('/cart')}
            />
            <MainLayout location={location}>{children}</MainLayout>
            {!location.pathname.startsWith('/cart') && <Footer/>}
          </ModalProvider>
        </ProductContextProvider>
      </ContextProvider>
    )
  }

  if (pageContext.layout === 'stripped') {
    return (
      <ContextProvider>
        <ModalProvider backgroundComponent={SpecialModalBackground}>
          <CookieBanner />
          <MainLayout location={location}>{children}</MainLayout>
        </ModalProvider>
      </ContextProvider>
    )  
  }

  return (
    <ContextProvider>
      <ModalProvider backgroundComponent={SpecialModalBackground}>
        <CookieBanner />
        <Header
          isNavShowing={!location.pathname.startsWith('/extended-warranty')}
          isCartPage={location.pathname.startsWith('/cart')}
        />
        <MainLayout location={location}>{children}</MainLayout>
        <Footer />
      </ModalProvider>
    </ContextProvider>
  )
}

export default ({ children, pageContext, location }: Props) => {
  return (
    <IpGeolocationProvider>
      <NotificationProvider>
        <CartProvider>
          <Page pageContext={pageContext} location={location}>
            <Notification />
            {children}
          </Page>
        </CartProvider>
      </NotificationProvider>
    </IpGeolocationProvider>
  )
}

const SpecialModalBackground = styled(BaseModalBackground)`
  background: linear-gradient(
    142.24deg,
    rgba(0, 40, 58, 0.8) 3.62%,
    rgba(0, 167, 225, 0.8) 100%
  );
`
