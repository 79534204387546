import React from 'react'
import styled from 'styled-components'
import { Grid, Cols, below } from 'styles'
import { SanitySection } from '../SanitySection'
import loadable from '@loadable/component'
const ComplexList = loadable(() => import('./ComplexList'))
const SimpleList = loadable(() => import('./SimpleList'))

import {
  SectionTheme,
  SectionPaddingSize,
  SectionSize,
  RowReference,
  TextAlign,
  ProductCopy,
  RowDropdownImage,
} from 'types/misc'

import { sizes } from 'styles'
import { Product } from './Product'



export type SanitySplitLayoutProps = {mediaItem: JSX.Element} & SanitySplitProps

export type SanitySplitProps = {
  theme?: SectionTheme | ''
  isMobileReverse?: boolean
  isSplitLayout?: boolean
  size?: SectionSize | ''
  paddingTop?: SectionPaddingSize | ''
  paddingBottom?: SectionPaddingSize | ''
  isMediaFirst?: boolean
  mediaItem: JSX.Element,
  list: string[]
  listBulletsType?: string
  listHasMargin?: boolean
  listHasAccentText?: boolean
  rowReference: RowReference[]
  alt: string
  id?: string
  title: string
  subtitle?: string
  _rawParagraph?: any[]
  hasComplexList?: boolean
  buttonLink?: string
  buttonText?: string
  buttonTheme?: string
  textAlign?: TextAlign
  addToCartEnabled?: boolean
  productPage?: ProductCopy,
  noRedirectToCart?: boolean
  goToCheckout?: boolean
  quantitySelectionEnabled?: boolean,
  onDropdownClicked?: (idx: number, imageAsset: RowDropdownImage) => void
}

export const SanitySplitLayout = ({
  theme = '',
  title = '',
  subtitle = '',
  isMobileReverse = false,
  isSplitLayout = true,
  hasComplexList = false,
  isMediaFirst,
  mediaItem,
  list,
  listBulletsType,
  listHasMargin = false,
  listHasAccentText = false,
  rowReference,
  _rawParagraph,
  size = '',
  paddingTop = '',
  paddingBottom = '',
  id = '',
  buttonLink = '',
  buttonText = '',
  buttonTheme = '',
  textAlign = 'left',
  addToCartEnabled,
  productPage,
  noRedirectToCart,
  goToCheckout,
  quantitySelectionEnabled,
  onDropdownClicked
}: SanitySplitProps) => {
  
  let nodeInfo = (
    <>
      {hasComplexList ? (
        <ComplexList
          rowReference={rowReference}
          buttonText={buttonText}
          buttonLink={buttonLink}
          buttonTheme={buttonTheme}
          onDropdownClicked={onDropdownClicked}
        />
      ) : (
        <SimpleList
          subtitle={subtitle}
          title={title}
          _rawParagraph={_rawParagraph}
          list={list}
          listBulletsType={listBulletsType}
          listHasAccentText={listHasAccentText}
          listHasMargin={listHasMargin}
          buttonText={buttonText}
          buttonLink={buttonLink}
          buttonTheme={buttonTheme}
          textAlign={textAlign}
        />
      )}
      {(productPage && addToCartEnabled) && (
        <Product
          productPage={productPage}
          customButtonText={buttonText}
          customButtonTheme={buttonTheme}
          noRedirectToCart={noRedirectToCart}
          goToCheckout={goToCheckout}
          quantitySelectionEnabled={quantitySelectionEnabled}
        />
      )}
    </>
  )

  const Content = (
    <>
      <SectionLeft
        className={`
          Section--left
          ${isMobileReverse ? 'Section--left__mobile-reverse' : ''}
          ${isMediaFirst ? '' : 'Section--text'}
        `}
      >
        {isMediaFirst ? mediaItem : nodeInfo}
      </SectionLeft>
      <SectionRight className={`
        Section--right
        ${isMediaFirst ? 'Section--text' : ''}
      `}>
        {isMediaFirst ? nodeInfo : mediaItem}
      </SectionRight>
    </>
  )

  const Header = hasComplexList && title && (
    <div className='center-text'>
      {subtitle && <h6>{subtitle}</h6>}
      <h2 className={`title-split ${subtitle ? 'no-top-margin' : ''}`}>{title}</h2>
    </div>
  )

  return (
    <SplitContainer paddingBottom={paddingBottom}>
      <SanitySection
        theme={theme}
        id={id}
        size={size}
        paddingTop={paddingTop || 'small'}
        paddingBottom={paddingBottom || 'small'}
      >
        {isSplitLayout ? (
          <>
            {Header}
            <Cols isReverse={isMediaFirst}>{Content}</Cols>
          </>
        ) : (
          <>
            {Header}
            <Grid cols={[1, 1, 2]}>{Content}</Grid>
          </>
        )}
      </SanitySection>
    </SplitContainer>
  )
}

export const SplitContainer = styled.div<{ paddingBottom: string }>`
  ${({ paddingBottom }) => paddingBottom === 'none' 
    ? 'border-bottom: none;'
    : 'border-bottom: 1px solid var(--lineColor);' 
  }
  
  .list-spacing,
  .list-has-margin {
    margin-bottom: var(--margin);
  }
  .image-container {
    position: relative;
    width: 100%;
    height: auto;
    max-height: 600px;
    @media screen and (max-width: ${sizes.medium}px) {
      display: flex;
      justify-content: center;
    }
  }
  .gatsby-image-wrapper {
    border-radius: var(--cardRadius);
    isolation: isolate;
    background-color: var(--productImgBackground);
    width: 100%;
    @media screen and (max-width: ${sizes.medium}px) {
      width: 75%;
    }
    @media screen and (max-width: ${sizes.small}px) {
      width: 100%;
    }
  }
  .Section--text {
    max-width: 500px;
    margin: 0 auto;
  }
  .accordion-wrapper {
    padding: .75rem 0;
    .h5 {
      font-size: 1.8rem;
    }
  }
`

export const SectionLeft = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  & > *:first-child {
    margin-top: 0;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
  h3:first-child,
  h2:first-child {
    margin-top: 0;
  }
  ${below.medium`
    &.Section--left__mobile-reverse {
      grid-row: 2;
    }
  `};
`

export const SectionRight = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  & > *:first-child {
    margin-top: 0;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
  h3:first-child,
  h2:first-child {
    margin-top: 0;
  }
`