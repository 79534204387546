/* Checks if the current date is between two given times */
// * Start and end times are converted to EST
// * Returns boolean

import { isBrowser } from './browser'

// * Optional 3rd 'now' param must be a date
export const isBetweenDates = async (startDate, endDate, now = new Date()) => {
  const { isWithinInterval } = await import('date-fns')

  const start = new Date(startDate)
  const end = new Date(endDate)
  let isBetween = false

  // Checks if a date is valid
  function isValidDate(date: Date) {
    return date instanceof Date && !isNaN(date)
  }

  if (isValidDate(start) && isValidDate(end)) {
    isBetween = isWithinInterval(now, {
      start,
      end,
    })
  }
  return isBetween
}

export const isDateInFuture = (dateStr: string) => {
  return new Date(dateStr).getTime() > new Date().getTime()
}

export const daysToMs = (day: number) => {
  return new Date().getTime() + day * 24 * 60 * 60 * 1000
}

export const msToDay = (ms: number) => {
  return new Date().getTime() + ms * 24 * 60 * 60 * 1000
}

export const convertDateToTime = (date: number | string) => {
  return typeof date === "number" ? daysToMs(date) : new Date(date).getTime();
}

export const stripNonAlphanumeric = (string: string) => {
  return string.replace(/[^a-zA-Z0-9]/g, '')
}

export const getFormattedDate = (date?: Date) => {
  const today = date || new Date()
  let dd = today.getDate()

  let mm = today.getMonth() + 1
  const yyyy = today.getFullYear()

  if (dd < 10) {
    dd = `0${dd}`
  }

  if (mm < 10) {
    mm = `0${mm}`
  }

  return `${mm}/${dd}/${yyyy}`
}

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

export const authorPositions = (positions: string[] | undefined) => {
  let res = ''
  if (!Array.isArray(positions)) return res
  positions.forEach((position, idx) => {
    if (idx == positions.length - 2) {
      res += position + ' & '
    } else if (idx != positions.length - 1) {
      res += position + ', '
    } else {
      res += position
    }
  })
  return res
}

export const scrollToArea = (id: string, waitToRunMS: number | null, offsetTopFurther?: number) => { 
  if (!isBrowser) return;
  const element = document.getElementById(id)
  const offsetTopFromElement = offsetTopFurther || 100

  const scrollToFn = () => window.scrollTo({
    behavior: 'smooth',
    top: element ? element.offsetTop - offsetTopFromElement : 0,
  });

  if (waitToRunMS) {
    setTimeout(scrollToFn, waitToRunMS);
    return;
  }

  scrollToFn();
}
