import React from 'react'
import styled from 'styled-components'
import { SanityVideo } from 'types/misc'
import { useWindowWidth } from 'hooks'
import { SanitySplitLayout, SanitySplitProps } from './SanitySplitLayout'
import { Video } from 'components/elements'

type SanitySplitVideoProps = {
  video: SanityVideo
  mobileVideo: SanityVideo
} & SanitySplitProps

export const SanitySplitVideo = ({
  theme = '',
  title = '',
  subtitle = '',
  isMobileReverse = false,
  isSplitLayout = true,
  hasComplexList = false,
  isMediaFirst,
  list,
  listBulletsType,
  listHasMargin = false,
  listHasAccentText = false,
  rowReference,
  _rawParagraph,
  alt,
  size = '',
  paddingTop = '',
  paddingBottom = '',
  id = '',
  buttonLink = '',
  buttonText = '',
  buttonTheme = '',
  textAlign = 'left',
  addToCartEnabled,
  productPage,
  noRedirectToCart,
  goToCheckout,
  quantitySelectionEnabled,
  video,
  mobileVideo
}: SanitySplitVideoProps) => {
  const windowWidth = useWindowWidth();
  const currentVideo = windowWidth && windowWidth > 768 ? video : mobileVideo;

  const nodeVideo = (
    <VideoContainer>
      <Video 
        title={currentVideo.title}
        sources={[
          {
            mimeType: 'video/mp4',
            src: currentVideo.videoMp4Src
          },
          {
            mimeType: 'video/webm',
            src: currentVideo.videoWebmSrc
          }
        ]}
        enableControls={false}
        posterImage={currentVideo.posterImage}
        playState={'play'}
      />
    </VideoContainer>
  )

  return (
    <SanitySplitLayout
      id={id}
      size={size}
      theme={theme}
      title={title}
      subtitle={subtitle}
      isMediaFirst={isMediaFirst}
      hasComplexList={hasComplexList}
      mediaItem={nodeVideo}
      list={list}
      listBulletsType={listBulletsType}
      listHasMargin={listHasMargin}
      listHasAccentText={listHasAccentText}
      rowReference={rowReference}
      alt={alt}
      _rawParagraph={_rawParagraph}
      isMobileReverse={isMobileReverse}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      buttonLink={!addToCartEnabled ? buttonLink : ''}
      buttonText={buttonText}
      buttonTheme={buttonTheme}
      isSplitLayout={isSplitLayout}
      textAlign={textAlign}
      addToCartEnabled={addToCartEnabled}
      goToCheckout={goToCheckout}
      productPage={productPage}
      noRedirectToCart={noRedirectToCart}
      quantitySelectionEnabled={quantitySelectionEnabled}
    />
  )
}

const VideoContainer = styled.div`
  video {
    border-radius: var(--cardRadius);
  }
`