import React from "react";
import { Button, Modal } from "components/elements";
import { Link } from "gatsby";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { below } from "styles";
import { hasUrlParam } from "utilities";
import { IpGeolocationContext } from "global";

const hasGivenConsent = (consents) => {
  for (let key in consents) {
    if (consents.hasOwnProperty(key)) {
      if (consents[key] !== '') {
        return true;
      }
    }
  }
  return false;
}

const copy = {
  title: "We value your privacy",
  description: "We use cookies and other technologies to personalize your experience, perform marketing, and collect analytics.",
  consentItems: {
    required: {
      title: "Required",
      description: "These cookies are necessary for the site to function properly, including capabilities like logging in and adding items to the cart."
    },
    marketing: {
      title: "Marketing",
      description: "These cookies are used to optimize marketing communications and show you ads on other sites."
    },
    analytics: {
      title: "Analytics",
      description: "These cookies help us understand how you interact with the site. We use this data to identify areas to improve."
    }
  },
  privacyUrl: {
    default: "/privacy-policy/",
    ccpa: "/privacy-policy-california/"
  },
  buttons: {
    accept: "Accept All",
    decline: "Decline All",
    manage: "Manage Preferences",
    save: "Save Preferences"
  }
}


declare global {
  interface Window {
    Shopify: {
      customerPrivacy: any;
    };
    gtag: any;
  }
}

const isDev = process.env.NODE_ENV !== "production";

const gtagShouldGrant = (shouldGrant: boolean) => shouldGrant ? 'granted' : 'denied'

type ShopifyConsent = { marketing: 'yes' | 'no', analytics: 'yes' | 'no' }
const convertCurrentConsent = (consent: ShopifyConsent) => {
  const convertToBoolean = (value: 'yes' | 'no') => value === 'yes' ? true : false;
  return {
    marketing: convertToBoolean(consent.marketing),
    analytics: convertToBoolean(consent.analytics)
  }
}
 
type ConsentPreferences = {
  marketing: boolean;
  analytics: boolean;
}

type ConsentDisplayProps = {
  content: BannerContentProps
  onAcceptAll: () => any
  onDeclineAll: () => any
}

type BannerProps = ConsentDisplayProps & { onShowPreferences: () => any }
const Banner = ({ content, onAcceptAll, onDeclineAll, onShowPreferences }: BannerProps) => {
  return (
    <BannerOverlay data-testid="cookie-banner-overlay" id="cookie-banner">
      <BannerDisplay data-testid="cookie-banner">
        <div className="content">
          {copy.description}
          {" "}Learn more in our <Link data-testid="cookie-banner-privacy-policy-link" to={content.privacyUrl}>Privacy Policy</Link>.
        </div>
        <div className="buttons">
          <Button data-testid="cookie-banner-button-accept" onClick={onAcceptAll}>{copy.buttons.accept}</Button>
          <Button data-testid="cookie-banner-button-decline-all" onClick={onDeclineAll}>{copy.buttons.decline}</Button>
          <Button data-testid="cookie-banner-button-manage" color="outline" onClick={onShowPreferences}>{copy.buttons.manage}</Button>
        </div>
      </BannerDisplay>
    </BannerOverlay>
  );
}

type BannerContentProps = {
  title: string;
  description: string;
  privacyUrl: string;
}

export const CookieBanner = () => {
  const [isModalActive, setModalActive] = useState(false);
  const [shouldShowBanner, setShouldShowBanner] = useState(false);
  const { geolocationData } = React.useContext(IpGeolocationContext);
  const [content, setContent] = useState<BannerContentProps>({
    title: copy.title,
    description: copy.description,
    privacyUrl: copy.privacyUrl.default
  });
  const [consent, setConsent] = useState<ConsentPreferences>({ marketing: false, analytics: false });
  const showBanner = () => {
    document.body.style.overflow = "hidden";
    setShouldShowBanner(true);
  }

  useEffect(() => {
    // disable banner on netlify preview
    if (process.env.GATSBY_NETLIFY) return;

    if (!window.gtag) {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function() { window.dataLayer.push(arguments); }
    }

    window.Shopify = window.Shopify || {};

    if (!window.Shopify?.customerPrivacy) {
      console.error("Shopify customer privacy script not loaded");
      return;
    }

    const currentConsent = window.Shopify.customerPrivacy.currentVisitorConsent();

    if (currentConsent) {
      setConsent(
        convertCurrentConsent(currentConsent)
      ); 
    }

    if (hasUrlParam('cm_enabled')) {
      setShouldShowBanner(true);
    } else {
      if (!hasGivenConsent(currentConsent)) {
        if (geolocationData.region === "California") {
          setContent({
            ...content,
            privacyUrl: copy.privacyUrl.ccpa
          })
        }

        showBanner();
      }
    }

    const openModal = () => setModalActive(true);

    window.addEventListener("consentUpdated", openModal)

    return () => {
      window.removeEventListener("consentUpdated", openModal)
      document.body.style.overflow = "auto";
    }
  }, [])

  const onShowPreferences = () => {
    setModalActive(true);
  }

  const onSavePreferences = (consent: ConsentPreferences) => {
      if (window.gtag) {
        window.gtag('consent', 'update', {
          ad_user_data: gtagShouldGrant(consent.marketing),
          ad_personalization: gtagShouldGrant(consent.marketing),
          ad_storage: gtagShouldGrant(consent.marketing),
          analytics_storage: gtagShouldGrant(consent.analytics)
        });
      }

      /**
       * @source https://shopify.dev/docs/api/customer-privacy#installation-on-a-custom-storefront
       */
      window.Shopify.customerPrivacy.setTrackingConsent(
        {
          "marketing": consent.marketing,
          "analytics": consent.analytics,
          "preferences": true,
          "headlessStorefront": true,
          "checkoutRootDomain": process.env.GATSBY_SHOPIFY_CUSTOM_DOMAIN,
          "storefrontRootDomain": isDev ? "localhost" : "rachio.com",
          "storefrontAccessToken": process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN
        }, () => {
          location.reload();
          console.log("Consent successfully updated")
        }
      )
  }

  const onAcceptAll = () => {
    onSavePreferences({ marketing: true, analytics: true });
  }

  const onDeclineAll = () => {
    onSavePreferences({ marketing: false, analytics: false });
  }

  return (
    <>
      {isModalActive && (
        <ConsentModal 
          content={content}
          onDeclineAll={onDeclineAll} 
          onSave={onSavePreferences}
          onClose={() => setModalActive(false)}
          isActive={isModalActive}
          consent={consent}
        />
      )}
      {shouldShowBanner && (
        <Banner 
          content={content}
          onAcceptAll={onAcceptAll} 
          onDeclineAll={onDeclineAll} 
          onShowPreferences={onShowPreferences}
        />
      )}
    </>
  )
}

type ConsentModalProps = { 
  onSave: (preferences: ConsentPreferences) => any, 
  onClose: () => any, 
  isActive: boolean 
  content: BannerContentProps
  consent: ConsentPreferences
  onDeclineAll: () => any
}
const ConsentModal = ({ onDeclineAll, onSave, content, onClose, consent }: ConsentModalProps) => {
  const [marketing, setMarketing] = useState(consent.marketing);
  const [analytics, setAnalytics] = useState(consent.analytics);

  return (
    <Modal closeAction={onClose} isActive maxWidth="768px">
      <ModalContent data-testid="cookie-banner-modal">
        <div className="title">{content.title}</div>
        <div className="buttons">
          <Button 
            data-testid="cookie-banner-button-decline" 
            color="white" 
            onClick={onDeclineAll}
          >
            {copy.buttons.decline}
          </Button>
          <Button 
            data-testid="cookie-banner-button-save" 
            onClick={() => onSave({ marketing, analytics })}
          >
            {copy.buttons.save}
          </Button>
        </div>
        <p>{content.description}</p>
        <div>
          <ConsentItem data-testid="consent-item-required">
            <div>
              <label>{copy.consentItems.required.title}</label>
              <div>{copy.consentItems.required.description}</div>
            </div>
            <input 
              data-testid="consent-item-required-checkbox"
              type="checkbox" 
              checked={true} 
              disabled={true} 
            />
          </ConsentItem>
          <ConsentItem data-testid="consent-item-marketing">
            <div>
              <label>{copy.consentItems.marketing.title}</label>
              <div>{copy.consentItems.marketing.description}</div>
            </div>
            <input 
              data-testid="consent-item-marketing-checkbox"
              type="checkbox" 
              checked={marketing} 
              onChange={() => setMarketing(!marketing)} 
            />
          </ConsentItem>
          <ConsentItem data-testid="consent-item-analytics">
            <div>
              <label>{copy.consentItems.analytics.title}</label>
              <div>{copy.consentItems.analytics.description}</div>
            </div>
            <input 
              data-testid="consent-item-analytics-checkbox"
              type="checkbox" checked={analytics} 
              onChange={() => setAnalytics(!analytics)} 
            />
          </ConsentItem>
        </div>
      </ModalContent>
    </Modal>
  )
};

const BannerOverlay = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: calc(var(--modalLevel) - 2);
  background: rgba(0, 0, 0, 0.5);
`

const BannerDisplay = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background-color: white;
  color: black;
  text-align: center;
  z-index: calc(var(--modalLevel) - 3);
  width: 100%;

  .content {
    margin: 0 auto 1rem;
    line-height: 2.1rem;
    font-size: 1.5rem;
  }

  .buttons {
    display: flex;
    justify-content: center;
    button {
      padding: 1rem;
      margin: .2rem;
      font-size: 1rem;
  
      @media(max-width: 399px) {
        padding: .5rem;
      }
    }
    button[color="outline"] {
      color: var(--black);
    }
  }
`;

const ModalContent = styled.div`
  font-size: 1.5rem;
  .title {
    margin: 3.5rem auto 2rem;
    font-size: 2rem;
  }

  .buttons {
    display: flex;
    button {
      padding: 1rem 2rem;
      font-size: 1rem;
      margin: 0;
      &:first-child {
        margin-right: .5rem;
      }
    }
  }

  label {
    margin: 0 auto .5rem;
    font-weight: bold;
    font-size: 1.6rem;
  }

  ${below.small`
    button {
      padding: .5rem 1rem;
      font-size: 1rem;
    }  
  `}
`

const ConsentItem = styled.div`
  display: flex;
  border: 1px solid var(--lineColor);
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 1rem;

  > div {
    margin: 1rem auto;
    width: 97%;
  }
  input {
    width: 2rem;
    margin-left: 2rem;
    width: 3%;
  }
  &:last-child {
    margin-bottom: 0;
  }

  ${below.small`
    > div {
    width: 93%;
    }
    input {
      width: 7%;
    } 
  `}
`