import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Icon } from "components/elements";
import { below } from "styles";
import Papa, { ParseResult } from "papaparse";

const Container = styled.div`
  margin-bottom: 3rem;
  
  table {
      border-spacing: 0;
  }

  small {
    font-weight: normal;
  }

  td, th {
    text-align: center;
    vertical-align: middle;
  }

  tr[data-label="Feature"] {
    border-bottom: 1px solid var(--lightesterGray);
    th, td {
      padding: 10px 0;
    }
  }

  tr[data-label="Category"] {
    background: var(--lightesterGray);
    th {
      padding: 1em 2em;
    }
  }

  .no-feature {
    position: relative;
    height: 20px;
    &:before {
      content: " ";
      position: absolute;
      width: 15px;
      top: calc(50% + 7.5px);
      left: calc(50% - 7.5px);
      height: 2px;
      background: var(--primaryBlue);
    }
  }

  .release-date,
  .fill-cell {
    display: none;
  }

  @media (min-width: 641px) {
    .fill-cell {
      display: table-cell;
    }
  }

  @media screen and (max-width: 40em) {
    tr[data-label="Category"] {
      margin-top: 40px;
    }

    .responsiveTable tbody tr {
        border: none;
        padding: 0.25em;
    }

    .responsiveTable td.pivoted {
      text-align: center !important;
    }

    tr[data-label="Feature"] {
      p {
        border-bottom: 1px solid;
        padding-bottom: 1em;
      }
    }
  }
`;

const ComparisonChart = styled.div`
  padding-top: var(--bottomSectionPadding);
  padding-bottom: var(--bottomSectionPadding);
  background: white;

  h2, 
  p {
    text-align: center;
  }

  p.description {
    margin: 20px auto 40px;
  }

  p {
    max-width: 768px;
    margin: 10px auto;
  }

  h2 {
    color: var(--navyBlue);
    margin: 0 20px;
  }

  table {
    max-width: 992px;
    margin: 0 auto;
  }

  ${below.small`
    p {
      padding: 0 20px;
    }
  `}
`

type ComparisonRow = {
  category: string,
  title: string,
  productValue1: string,
  productValue2: string
}

type ComparisonStructure = { 
  products: string[], 
  rowsByCategory: { 
    [key: string]: ComparisonRow[] 
  } 
}

const parseCsv = async (
  url: string, 
  onComplete: (struct: ComparisonStructure) => any
) => {
  try {
    const res = await fetch(url);
    const csvString = await res.text();
    const parseResult = Papa.parse(csvString, { dynamicTyping: true }) as ParseResult<string[]>
  
    if (!parseResult.data || (parseResult.data[0].length < 4 || parseResult.data[0].length > 4)) {
      throw new Error('CSV comparison chart is not properly formatted. Please reformat and re-upload.');
    }

    const products = [parseResult.data[0][1], parseResult.data[0][2]];

    // remove headers
    parseResult.data.shift();

    const rowsSortedByCategory = parseResult.data.reduce((previouState, currentRow) => {
      const formattedRow = {
        category: currentRow[3],
        title: currentRow[0],
        productValue1: currentRow[1],
        productValue2: currentRow[2]
      }

      if (previouState[formattedRow.category]) {
        previouState[formattedRow.category].push(formattedRow)
      } else {
        previouState[formattedRow.category] = [formattedRow]
      }

      return previouState
    }, {})
  
    onComplete({
      products,
      rowsByCategory: rowsSortedByCategory
    }); 
  } catch (error) {
    console.error(error);
  }
}

type FeatureTableRowProps = {
  title: string,
  rows: ComparisonRow[]
  firstRow?: boolean,
  hideCategory?: boolean
}
const CategorizedTablePartial = ({ title, rows, hideCategory }: FeatureTableRowProps) => {
  return (
    <>
      {!hideCategory && (
        <Tr data-label="Category">
          <Th>
            <strong>{title}</strong>
          </Th>
          <Th className="fill-cell" />
          <Th className="fill-cell" />
        </Tr>
      )}
      {rows.map((item, index) => (
        <TableRow item={item} key={`${item.title}-${index}`} />)
      )}
    </>
  )
}
const TableRow = ({ item }: { item: ComparisonRow }) => {
  const mapComparisonValue = (value: string | boolean) => {
    if (typeof value === 'boolean') {
      return (
        <Td>
          {
            value
              ? <Icon color="var(--primaryColor)" name="check" />
              : <span className="no-feature"></span>
            }
        </Td>
      )
    }

    return <Td><div dangerouslySetInnerHTML={{ __html: value }} /></Td>
  }

  return (
    <Tr data-label="Feature">
      <Th>
        <p dangerouslySetInnerHTML={{ __html: item.title }} />
      </Th>

      {mapComparisonValue(item.productValue1)}
      {mapComparisonValue(item.productValue2)}
    </Tr>
  );
}

type Props = {
  title: string
  description?: string
  hideCategories?: boolean
  csvUrl: string
}
const SanityProductComparisonChart = ({ title, description, csvUrl, hideCategories }: Props) => {
  const [comparisonStructure, setComparisonStructure] = useState<ComparisonStructure|null>(null);

  useEffect(() => {
    parseCsv(csvUrl, setComparisonStructure)
  }, [])

  if (!comparisonStructure) return null;

  return (
    <ComparisonChart>
      <h2>{title}</h2>
      {description && <p className='description'>{description}</p>}
      <Container>
        <Table>
          <Thead>
            <Tr>
              <Th></Th>
              <Th>
                <div><strong>{comparisonStructure.products[0]}</strong></div>
              </Th>
              <Th>
                <div><strong>{comparisonStructure.products[1]}</strong></div>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {Object.keys(comparisonStructure.rowsByCategory).map((category, index) => (
              <CategorizedTablePartial 
                title={category} 
                rows={comparisonStructure.rowsByCategory[category]} 
                hideCategory={hideCategories} 
                key={`${category}-${index}`}
              />  
            ))}
          </Tbody>
        </Table>
      </Container>
    </ComparisonChart>
  )
}

export default SanityProductComparisonChart
