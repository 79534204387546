import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { useDiscount } from 'global'
import { ProductCopy } from 'types/misc'
import ProductBadge from 'components/product/ProductBadge'
import { Price } from 'components/product'
import { getBasePrice, getDefaultShopifyProductImage } from 'utilities'
import { ShopifyProductWithoutImages, ShopifyProductVariant } from 'lib/shopify/storefront-api-client/types/custom.types'

type Props = {
  product: ShopifyProductWithoutImages
  copy: ProductCopy
  emailListId?: string
  arePriceVariantsShowing?: boolean
  displayLearnMoreButton?: boolean
  buttonDisabled?: boolean
}

type HeaderProps = {
  product: ShopifyProductWithoutImages,
  copy: ProductCopy,
  mediaItem: { image: { gatsbyImageData: IGatsbyImageData } },
  selectedVariant: ShopifyProductVariant
}

const Header = ({ product, copy, mediaItem, selectedVariant }: HeaderProps) => {
  return (
    <>
      <div className="product-image-wrapper">
        <Link to={copy.seoPathname}>
          {copy.badgeEnabled && (
            <ProductBadge
              product={{
                ...product,
                copy
              }}
            />
          )}
          <GatsbyImage
            image={mediaItem.image.gatsbyImageData}
            alt={`${selectedVariant.title}`}
            objectFit="contain"
          />
        </Link>
      </div>

      <Title>
        <Link to={copy.seoPathname}>
          <div className="product-title-wrapper">
            <h2 className="product-title no-margins">
              {product.title}
            </h2>
          </div>
        </Link>
      </Title>
    </>
  )
}

export function CollectionCard({
  product,
  copy
}: Props) {
  if (!copy || !product) return null

  const { getDiscountAmount } = useDiscount();

  const firstAvailableVariant = product.variants.find(v => v.availableForSale) || product.variants[0]

  const productImage = getDefaultShopifyProductImage(product);

  return (
    <CollectionCardContainer data-testid="product-preview" className="product-preview">
      <div className="product-wrapper" data-testid={product.handle}>
        <div>
          <Header
            product={product}
            copy={copy}
            mediaItem={productImage}
            selectedVariant={product.variants[0]}
          />

          <p className="product-description">
            {
              copy.shortDescription.length > 80 
                ? `${copy.shortDescription.slice(0, 80)}...`
                : copy.shortDescription
            }
          </p>
        </div>
        <div className='pricing'>
          <span>From</span>
          <Price
            basePrice={getBasePrice(firstAvailableVariant)}
            discountAmount={getDiscountAmount(firstAvailableVariant)}
            type='tiny'
          />
        </div>
      </div>
    </CollectionCardContainer>
  )
}

export const Title = styled.div``

export const CollectionCardContainer = styled.div`
  .product-image-wrapper {
    position: relative;
    border-bottom: 1px solid var(--lightGray);

    .product-badge {
      position: absolute;
      top: 8px;
      left: 8px;
    }
  }

  .product-title-wrapper,
  .product-description,
  .pricing {
    padding: 0 15px;
  }

  .product-title-wrapper {
    min-height: 50px;
    margin-top: 20px;
    margin-bottom: 5px;

    a {
      margin: 0;
    }

    .product-subtitle {
      margin-top: 5px;
      text-transform: initial;
      margin-bottom: 0;
      color: var(--secondaryColor);
      font-size: var(--h5);
    }
    .product-title {
      font-size: 16px;
      color: var(--headlineColor);
    }
  }
 
  .pricing {
    font-family: var(--headlineFont);
  }

  .pricing {
    color: var(--primaryColor);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    padding: 15px;
    padding-top: 0;
    font-size: 14px;
    .price-text {
      display: flex;
    }
    .price {
      margin-right: 2px;
    }
    .price, .discount {
      font-size: 14px;
    }
    > span {
      margin-right: 5px;
    }
  }

  .gatsby-image-wrapper,
  .gatsby-image-wrapper [data-main-image] img {
    width: 100%;
  }
  .gatsby-image-wrapper [data-main-image] {
    border-top-left-radius: var(--cardRadius);
    border-top-right-radius: var(--cardRadius);
  }
  .product-wrapper {
    border-radius: var(--cardRadius);
    background: white;
  }
  .product-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    border: 1px solid var(--lightGray);
    .product-description {
      color: var(--darkGray);
      margin: 0;
      min-height: 95px;
      font-size: 14px;
      padding-bottom: 15px;
      line-height: 20px;
    }
    a,
    a:hover {
      h6 {
        color: var(--textColor);
      }
    }
  }

  @media (min-width: 1440px) {
    .product-wrapper {
      .product-description {
        min-height: 80px;
      } 
      .product-title-wrapper {
        min-height: 45px;
      }
    }
  }

  @media (max-width: 768px) {
    .product-title-wrapper, .pricing {
      padding: 10px;
      margin-top: 0px;
    }

    .product-title-wrapper {
      padding-bottom: 5px;
    }

    .product-title-wrapper .product-title {
      font-size: 13px;
    }

    .product-description {
      display: none;
    }
  }

  @media (max-width: 600px) {
    .product-title-wrapper {
      min-height: 60px;
    }

    .pricing {
      font-size: 12px;
      .price, .discount {
        font-size: 12px;
      }
    }
  }
`