import { redactUrlPII } from '../../common/utilities';

export const getUrlParam = (param) => {
  if (typeof window !== 'undefined') {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    return urlParams?.get(param)
  }
}

export const hasUrlParam = (param) => {
  if (typeof window !== 'undefined') {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    return urlParams?.has(param)
  }
}

export const transformParams = (url: string) => {
  const idx = url.indexOf('?') + 1
  const preQuery = url.slice(0, idx)
  const postQuery = url.slice(idx)
  const replaced = postQuery.replace(/\?/g, '&')
  return preQuery + replaced
}

export const checkParams = (param: string, setParam: (_: string) => void) => {
  if (hasUrlParam(param)) {
    const urlParams = getUrlParam(param)
    setParam(urlParams || '')
    return urlParams
  }
}

export const getAllUrlParams = () => {
  if (typeof window === 'undefined') return null;
  const redactedUrlParams = redactUrlPII(window.location);
  const urlParams = new URLSearchParams(redactedUrlParams.search);
  const params: { [key: string]: string } = {};
  urlParams.forEach((value, key) => {
    params[key] = value;
  });
  if (Object.keys(params).length === 0) return null;
  return params;
}

export const isBrowser = typeof window !== 'undefined'